import React from "react";

export default function TableHeader(props) {
  return (
    <div
      style={{
        height: 40,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "lightgray",
        backgroundColor: "#f5f5fc",
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        fontWeight: "bold",
        color: "black",
        margin: 5,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        SYMBOL
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        BID
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        ASK
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        LTP
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        CHANGE
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        OPEN
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        HIGH
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        LOW
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          borderRightStyle: "solid",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          borderRightWidth: 1,
          borderRightColor: "lightgray",
        }}
      >
        CLOSE
      </div>
      {props.noDelete == true ? null : (
        <div
          style={{
            flex: 0.5,
            display: "flex",
            borderRightStyle: "solid",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRightWidth: 0,
            borderRightColor: "lightgray",
          }}
        >
          x
        </div>
      )}
    </div>
  );
}
