// NotificationContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { Howl, Howler } from "howler";
import successAudio from "./noti.mp3";
import errorAudio from "./noti.mp3";

const NOTIFICATION_STORAGE_KEY = "notificationHistory";
const CLEANUP_HOUR = 6; // Hour for cleanup (6 AM)
const CLEANUP_INTERVAL = 24 * 60 * 60 * 1000; // Cleanup interval (24 hours)

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  Howler.volume(1.0); // Adjust the volume if needed

  // Load notifications from local storage only if they haven't been loaded before
  useEffect(() => {
    // if (notifications.length === 0) {
    //   const storedNotifications =
    //     JSON.parse(localStorage.getItem(NOTIFICATION_STORAGE_KEY)) || [];
    //   setNotifications(storedNotifications);
    // }
  }, [notifications]);

  const addNotification = (message, type) => {
    const id = Date.now();
    let soundFile;

    if (type === "success") {
      soundFile = successAudio;
    } else if (type === "error") {
      soundFile = errorAudio;
    }

    const sound = new Howl({
      src: [soundFile],
    });

    sound.play();

    const newNotification = {
      id,
      message,
      type,
      timestamp: Date.now(),
      soundFile,
    };

    // Add the new notification to the list
    setNotifications((prevNotifications) => [
      newNotification,
      ...prevNotifications,
    ]);

    // Store the notifications in local storage
    const updatedNotifications = JSON.stringify([
      newNotification,
      ...notifications,
    ]);
    localStorage.setItem(NOTIFICATION_STORAGE_KEY, updatedNotifications);

    return id;
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  // Automatically remove notifications older than today morning at 6 AM
  const cleanupNotifications = () => {
    const now = Date.now();
    const today6AM = new Date();
    today6AM.setHours(CLEANUP_HOUR, 0, 0, 0); // Set the time to 6 AM today

    if (now >= today6AM.getTime()) {
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.timestamp >= today6AM.getTime()
        )
      );
    }
  };

  // Periodically clean up notifications
  useEffect(() => {
    const cleanupInterval = setInterval(cleanupNotifications, CLEANUP_INTERVAL);

    return () => clearInterval(cleanupInterval);
  }, []);

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
