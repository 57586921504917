import React, { useContext, useEffect } from "react";
import SocketContect from "../../SocketContext/SocketContext";

export default function UpdateTrade(props) {
  const testing = () => {
    props.update();
  };
  const socket = useContext(SocketContect);
  useEffect(() => {
    socket.on("tradeupdate", testing);
    return () => {
      socket.off("tradeupdate", testing);
    };
  }, []);

  return <div></div>;
}
